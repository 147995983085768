<template>
  <div class="align window-height-Technologies">
    <div class="section-title fade" v-scrollanimation>
      <h2 class="section-header">Other Products</h2>
    </div>

    <div class="description moveUp" v-scrollanimation>
      We are expert in computer vision and deep edge technology. In addition to
      FSD, we are also empowering manufacturing companies for Industry 4.0 and
      5.0.
    </div>

    <div class="upload-btn moveUp">
      <q-btn
        label="Read More"
        no-caps
        class="q-mx-lg q-my-lg text-white description upld-btn"
        :to="{ name: 'Tech' }"
        push
      />
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/globalStyles.scss";

.upload-btn {
  display: flex;
  justify-content: center;
}

.upld-btn {
  background-color: #e6c84f;
  background-image: linear-gradient(315deg, #e4d535 0%, #f43e3b 74%);
  padding-top: 15px;
  padding-bottom: 15px;
  width: 250px;

  &:hover {
    animation: burn 2000ms ease-out forwards infinite;
  }
}
</style>
